"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.format = format;
exports.formatBibtex = formatBibtex;

var _index = require("../mapping/index.js");

var _value = require("./value.js");

function formatEntryValues({
  type,
  label,
  properties
}) {
  const output = {};

  for (const property in properties) {
    const value = properties[property];
    output[property] = (0, _value.format)(property, value);
  }

  return {
    type,
    label,
    properties: output
  };
}

function format(entries) {
  return (0, _index.format)(entries).map(formatEntryValues);
}

function formatBibtex(entries) {
  return (0, _index.formatBibtex)(entries).map(formatEntryValues);
}